const Layout = () => import("@/layout/index.vue");
const LayoutNf = () => import("@/layout/nfIndex.vue"); //农服
const LayoutNz = () => import("@/layout/nzIndex.vue"); //农资
const LayoutRyp = () => import("@/layout/rypIndex.vue"); //日用品

const LayoutZs = () => import("@/layout/zsIndex.vue"); //再生资源回收

const flag = import.meta.env.VITE_APP_FLAG;
let redirect = "";
if (flag == "nz") {
  //农资
  redirect = "/nzHomePage";
} else if (flag == "ly") {
  //粮油
  redirect = "/lyHomePage";
} else if (flag == "ryp") {
  //日用品
  redirect = "/rypHomePage";
} else {
  redirect = "/homePage";
}

const routes = [
  {
    path: "/lyHomePage",
    component: LayoutNf,
    meta: { title: "粮油产业链首页" },
    children: [
      {
        path: "",
        component: () => import("@/views/homePage/nfIndex.vue"),
        name: "粮油产业链首页",
        meta: { title: "粮油产业链平台" },
      },
      {
        path: "/agrochemical",
        component: () => import("@/views/agrochemical/index.vue"),
        name: "农业社会化服务大厅",
        meta: { title: "农业社会化服务大厅" },
      },
      {
        path: "/agrochemical/details",
        component: () => import("@/views/agrochemical/details.vue"),
        name: "农化服务详情",
        meta: { title: "农化服务详情" },
      },
      {
        path: "/ly-supply-demand",
        component: () => import("@/views/supply-demand/index.vue"),
        name: "粮油供需服务大厅",
        meta: { title: "粮油供需服务大厅" },
      },
      {
        path: "/ly-supply-demand/details",
        component: () => import("@/views/supply-demand/details.vue"),
        name: "粮油供需详情",
        meta: { title: "粮油供需详情" },
      },

      {
        path: "/ly-service/xzda",
        component: () => import("@/views/ly/xzda/index.vue"),
        name: "选种定案",
        meta: { title: "选种定案" },
      },
      {
        path: "/ly-service/xzda2",
        redirect: "/ly-service/xzda",
        //name: "选种定案",
        meta: { title: "选种定案" },
        children: [
          {
            path: "/ly-service/xzda/detail/:id",
            component: () => import("@/views/ly/xzda/detail.vue"),
            name: "选种定案详情",
            meta: { title: "详情" },
          },
        ],
      },
      {
        path: "/ly-service/nsfw",
        component: () => import("@/views/ly/nsfw/index.vue"),
        name: "农事服务",
        meta: { title: "农事服务" },
      },
      {
        path: "/ly-service/nsfw2",
        redirect: "/ly-service/nsfw",
        meta: { title: "农事服务" },
        children: [
          {
            path: "/ly-service/nsfw/htqd",
            component: () => import("@/views/ly/htqd/index.vue"),
            name: "合同签订",
            meta: { title: "合同签订" },
          },
          {
            path: "/ly-service/nsfw/htgl",
            component: () => import("@/views/ly/htgl/index.vue"),
            name: "合同管理",
            meta: { title: "合同管理" },
          },
          {
            path: "/ly-service/nsfw/ddgl",
            component: () => import("@/views/ly/ddgl/index.vue"),
            name: "订单管理",
            meta: { title: "订单管理" },
          },
          {
            path: "/ly-service/nsfw/groupBuyPd",
            component: () => import("@/views/ly/nsfw/pdList.vue"),
            name: "拼单专区",
            meta: { title: "拼单专区" },
          },
        ],
      },
      {
        path: "/ly-service/chfw",
        component: () => import("@/views/ly/chfw/index.vue"),
        name: "产后服务",
        meta: { title: "产后服务" },
      },
      {
        path: "/ly-service/zczx",
        component: () => import("@/views/ly/zczx/index.vue"),
        meta: { title: "政策资讯" },
        children: [
          {
            path: "/ly-service/zczx/details",
            component: () => import("@/views/ly/zczx/detail.vue"),
            meta: { title: "详情" },
          },
        ],
      },
    ],
  },
  {
    path: "/nzHomePage",
    component: LayoutNz,
    meta: { title: "农资产业链平台首页" },
    children: [
      {
        path: "",
        component: () => import("@/views/homePage/nzIndex2.vue"),
        name: "农资产业链平台首页",
        meta: { title: "农资产业链平台" },
      },
      {
        path: "/commodity",
        component: () => import("@/views/commodity/index.vue"),
        name: "优选农资商城",
        meta: { title: "优选农资商城" },
      },
      {
        path: "/commodity/details", ///:goodsTypeId
        component: () => import("@/views/commodity/details.vue"),
        name: "农资商品详情",
        meta: { title: "农资商品详情" },
      },
      {
        path: "/commodity/shop",
        component: () => import("@/views/commodity/index.vue"),
        name: "农资商家店铺",
        meta: { title: "商家店铺" },
      },
      {
        path: "/commodity/shopDetails",
        component: () => import("@/views/commodity/shopDetails.vue"),
        name: "农资商家店铺详情",
        meta: { title: "商家店铺详情" },
      },
      {
        path: "/groupBuy",
        component: () => import("@/views/groupBuy/index.vue"),
        name: "农资团购专区",
        meta: { title: "农资团购专区" },
      },
      {
        path: "/nz-supply-demand",
        component: () => import("@/views/supply-demand/index.vue"),
        name: "农资供需服务大厅",
        meta: { title: "农资供需服务大厅" },
      },

      {
        path: "/nz-supply-demand/details",
        component: () => import("@/views/supply-demand/details.vue"),
        name: "农资供需详情",
        meta: { title: "农资供需详情" },
      },
      {
        path: "/nzHomePage/expertDatabase",
        component: () =>
          import("@/views/CropHospital/expertDatabase/index.vue"),
        name: "专家库",
        meta: { title: "专家库" },
        children: [
          {
            path: "/nzHomePage/expertDatabase/details",
            component: () =>
              import("@/views/CropHospital/expertDatabase/detail.vue"),
            name: "专家详情",
            meta: { title: "专家详情" },
          },
        ],
      },
      {
        path: "/nzHomePage/agriculturalReport",
        component: () =>
          import("@/views/CropHospital/agriculturalReport/index.vue"),
        name: "行业资讯",
        meta: { title: "行业资讯" },
        children: [
          {
            path: "/nzHomePage/agriculturalReport/details",
            component: () =>
              import("@/views/CropHospital/agriculturalReport/detail.vue"),
            name: "行业资讯详情",
            meta: { title: "行业资讯详情" },
          },
        ],
      },
      {
        path: "/nzHomePage/onlineLecture",
        component: () => import("@/views/CropHospital/onlineLecture/index.vue"),
        name: "线上讲堂",
        meta: { title: "线上讲堂" },
        children: [
          {
            path: "/nzHomePage/onlineLecture/details",
            component: () =>
              import("@/views/CropHospital/onlineLecture/detail.vue"),
            name: "线上讲堂详情",
            meta: { title: "线上讲堂详情" },
          },
        ],
      },
      {
        path: "/nzHomePage/agriculturalTechnologyConsulting",
        component: () =>
          import(
            "@/views/CropHospital/agriculturalTechnologyConsulting/index.vue"
          ),
        name: "农技咨询",
        meta: { title: "农技咨询" },
        children: [
          {
            path: "/nzHomePage/agriculturalTechnologyConsulting/details",
            component: () =>
              import(
                "@/views/CropHospital/agriculturalTechnologyConsulting/detail.vue"
              ),
            name: "农技咨询详情",
            meta: { title: "农技咨询详情" },
          },
        ],
      },
      {
        path: "/nzHomePage/FinancialService",
        component: () =>
          import(
            "@/views/IndustryChainIntegratedServices/FinancialService/index.vue"
          ),
        name: "金融服务",
        meta: { title: "金融服务" },
        children: [
          {
            path: "/nzHomePage/FinancialService/apply",
            component: () =>
              import(
                "@/views/IndustryChainIntegratedServices/FinancialService/apply.vue"
              ),
            name: "金融服务申请",
            meta: { title: "金融服务申请" },
          },
        ],
      },
      {
        path: "/nzHomePage/WarehousingServices",
        component: () =>
          import(
            "@/views/IndustryChainIntegratedServices/WarehousingServices/index.vue"
          ),
        name: "仓储服务",
        meta: { title: "仓储服务" },
        children: [
          {
            path: "/nzHomePage/WarehousingServices/details",
            component: () =>
              import(
                "@/views/IndustryChainIntegratedServices/WarehousingServices/detail.vue"
              ),
            name: "仓储服务详情",
            meta: { title: "仓储服务详情" },
          },
        ],
      },
      {
        path: "/nzHomePage/LogisticsServices",
        component: () =>
          import(
            "@/views/IndustryChainIntegratedServices/LogisticsServices/index.vue"
          ),
        name: "物流服务",
        meta: { title: "物流服务" },
        children: [
          {
            path: "/nzHomePage/LogisticsServices/details",
            component: () =>
              import(
                "@/views/IndustryChainIntegratedServices/LogisticsServices/detail.vue"
              ),
            name: "物流服务详情",
            meta: { title: "物流服务详情" },
          },
        ],
      },
      {
        path: "/nzHomePage/QualityTraceabilityService",
        component: () =>
          import(
            "@/views/IndustryChainIntegratedServices/QualityTraceabilityService/index.vue"
          ),
        name: "质量追溯服务",
        meta: { title: "质量追溯服务" },
        children: [
          {
            path: "/nzHomePage/QualityTraceabilityService/details",
            component: () =>
              import(
                "@/views/IndustryChainIntegratedServices/QualityTraceabilityService/detail.vue"
              ),
            name: "质量追溯服务详情",
            meta: { title: "质量追溯服务详情" },
          },
        ],
      },
      //
      {
        path: "/nzHomePage/EnterpriseServices",
        component: () =>
          import(
            "@/views/IndustryChainIntegratedServices/EnterpriseServices/index.vue"
          ),
        name: "企业展示服务",
        meta: { title: "企业展示服务" },
        children: [
          {
            path: "/nzHomePage/EnterpriseServices/details",
            component: () =>
              import(
                "@/views/IndustryChainIntegratedServices/EnterpriseServices/detail.vue"
              ),
            name: "企业详情",
            meta: { title: "企业详情" },
          },
        ],
      },
      {
        path: "/cxjj",
        component: () => import("@/views/cxjj/index.vue"),
        name: "采销竞价",
        meta: { title: "采销竞价" },
      },
      {
        path: "/cxjj2",
        meta: { title: "采销竞价" },
        redirect: "/cxjj",
        children: [
          {
            path: "/cxjj/detail",
            component: () => import("@/views/cxjj/detail.vue"),
            name: "竞拍详情",
            meta: { title: "竞拍详情" },
          },
          {
            path: "/cxjj/deposit",
            component: () => import("@/views/cxjj/detail/deposit.vue"),
            name: "保证金",
            meta: { title: "保证金" },
          },
          {
            path: "/cxjj/fillin",
            component: () => import("@/views/cxjj/detail/fillin.vue"),
            name: "填写竞价信息",
            meta: { title: "填写信息" },
          },
        ],
      },
    ],
  },
  {
    path: "/rypHomePage",
    component: LayoutRyp,
    meta: { title: "日用品产业链平台首页" },
    children: [
      {
        path: "",
        component: () => import("@/views/homePage/rypIndex.vue"),
        name: "日用品产业链平台首页",
        meta: { title: "日用品产业链平台" },
      },
      {
        path: "/rypCommodity",
        component: () => import("@/views/commodity/index.vue"),
        name: "优选日用品商城",
        meta: { title: "优选日用品商城" },
      },
      {
        path: "/rypCommodity/details",
        component: () => import("@/views/commodity/details.vue"),
        name: "日用品商品详情",
        meta: { title: "日用品商品详情" },
      },
      {
        path: "/rypCommodity/shop", ///:goodsTypeId
        component: () => import("@/views/commodity/index.vue"),
        name: "日用品商家店铺",
        meta: { title: "商家店铺" },
      },
      {
        path: "/rypCommodity/shopDetails",
        component: () => import("@/views/commodity/shopDetails.vue"),
        name: "日用品商家店铺详情",
        meta: { title: "商家店铺详情" },
      },
      {
        path: "/ryp-supply-demand",
        component: () => import("@/views/supply-demand/index.vue"),
        name: "日用品供需服务大厅",
        meta: { title: "日用品供需服务大厅" },
      },
      {
        path: "/ryp-supply-demand/details",
        component: () => import("@/views/supply-demand/details.vue"),
        name: "日用品供需详情",
        meta: { title: "日用品供需详情" },
      },
    ],
  },
  {
    path: "/zsHomePage",
    component: LayoutZs,
    children: [
      {
        path: "/zsHomePage",
        component: () => import("@/views/homePage/zsIndex.vue"),
        name: "再生资源回收首页",
        meta: { title: "再生资源回收首页" },
      },
    ],
  },

  {
    path: "",
    component: Layout,
    meta: { title: "首页" },
    redirect: redirect,
    children: [
      {
        path: "/homePage",
        component: () => import("@/views/homePage/index.vue"),
        name: "首页",
        meta: { title: "" },
      },
      {
        path: "/help-center",
        component: () => import("@/views/help-center/index.vue"),
        name: "帮助中心",
        meta: { title: "帮助中心" },
      },
      {
        path: "/help-center2",
        redirect: "/help-center",
        meta: { title: "帮助中心" },
        children: [
          {
            path: "/help-center/detail/:id",
            component: () => import("@/views/help-center/detail.vue"),
            meta: { title: "详情" },
          },
        ],
      },
      {
        path: "/register",
        component: () => import("@/views/register/index.vue"),
        name: "注册",
        meta: { title: "注册" },
      },
      {
        path: "/policy-info",
        component: () => import("@/views/policy-info/index.vue"),
        name: "政策资讯",
        meta: { title: "政策资讯" },
        children: [
          {
            path: "/policy-info/details",
            component: () => import("@/views/policy-info/details.vue"),
            name: "详情",
            meta: { title: "详情" },
          },
        ],
      },

      {
        path: "/accredit",
        component: () => import("@/views/accredit/index.vue"),
        name: "地标授权",
        meta: { title: "地标授权" },
      },
      {
        path: "/accredit/details",
        component: () => import("@/views/accredit/details.vue"),
        name: "地标授权详情",
        meta: { title: "地标授权详情" },
      },
      /* {
                                  path: "/finance",
                                  component: () => import("@/views/finance/index.vue"),
                                  name: "金融服务",
                                  meta: { title: "金融服务" },
                              },
                              {
                                  path: "/finance/details",
                                  component: () => import("@/views/finance/details.vue"),
                                  name: "金融服务详情",
                                  meta: { title: "金融服务详情" },
                              }, */
      {
        path: "/supply-demand",
        component: () => import("@/views/supply-demand/index.vue"),
        name: "供需服务大厅",
        meta: { title: "供需服务大厅" },
        children: [
          {
            path: "/supply-demand/details",
            component: () => import("@/views/supply-demand/details.vue"),
            name: "供需详情",
            meta: { title: "供需详情" },
          },
        ],
      },
      {
        path: "/market",
        component: () => import("@/views/market/index.vue"),
        name: "市场行情",
        meta: { title: "市场行情" },
      },
      // 个人中心
      {
        path: "/user-center",
        component: () => import("@/views/user-center/index.vue"),
        redirect: "/user-center/cart",
        name: "用户中心",
        meta: { title: "用户中心" },
        children: [
          {
            path: "/user-center/cart",
            component: () => import("@/views/user-center/cart/index.vue"),
            name: "我的购物车",
            meta: { title: "我的购物车" },
          },
          {
            path: "/user-center/collect",
            component: () => import("@/views/user-center/collect/index.vue"),
            name: "我的收藏",
            meta: { title: "我的收藏" },
          },
          {
            path: "/user-center/demandPublish",
            component: () =>
              import("@/views/user-center/demandPublish/index.vue"),
            name: "我的发布",
            meta: { title: "我的发布" },
          },
          {
            path: "/user-center/order",
            component: () => import("@/views/user-center/order/index.vue"),
            name: "我的订单",
            meta: { title: "我的订单" },
          },
          {
            path: "/user-center/myAddress",
            component: () => import("@/views/user-center/myAddress/index.vue"),
            name: "我的地址",
            meta: { title: "我的地址" },
          },
          /*  {
                                                                                                         path: '/user-center/member-center',
                                                                                                         component: () => import('@/views/user-center/member-center/index.vue'),
                                                                                                         name: '会员中心',
                                                                                                         meta: { title: '' },
                                                                                                         children: [
                                                                                                             {
                                                                                                                 path: '',
                                                                                                                 component: () => import('@/views/user-center/member-center/home/index.vue'),
                                                                                                                 name: '会员中心',
                                                                                                                 meta: { title: '会员中心' },
                                                                                                             },
                                                                                                             {
                                                                                                                 path: 'member-active',
                                                                                                                 component: () => import('@/views/user-center/member-center/active/index.vue'),
                                                                                                                 name: '会员激活',
                                                                                                                 meta: { title: '会员激活' },
                                                                                                             },
                                                                                                             {
                                                                                                                 path: 'member-apply',
                                                                                                                 component: () => import('@/views/user-center/member-center/apply/index.vue'),
                                                                                                                 name: '供销社员申请',
                                                                                                                 meta: { title: '供销社员申请' },
                                                                                                             },
                                                                                                             {
                                                                                                                 path: 'points-details',
                                                                                                                 component: () => import('@/views/user-center/member-center/points-details/index.vue'),
                                                                                                                 name: '积分明细',
                                                                                                                 meta: { title: '积分明细' },
                                                                                                             },
                                                                                                             {
                                                                                                                 path: 'card-details',
                                                                                                                 component: () => import('@/views/user-center/member-center/card/index.vue'),
                                                                                                                 name: '会员卡详情',
                                                                                                                 meta: { title: '会员卡详情' },
                                                                                                             },
                                                                                                             {
                                                                                                                 path: 'goods-details',
                                                                                                                 component: () => import('@/views/user-center/member-center/goods/index.vue'),
                                                                                                                 name: '积分商城',
                                                                                                                 meta: { title: '积分商城' },
                                                                                                             }
                                                                                                         ]
                                                                                                     }, */
          {
            path: "/user-center/myInformation",
            component: () =>
              import("@/views/user-center/customer-service/index.vue"),
            name: "我的消息",
            meta: { title: "我的消息" },
          },
          {
            path: "/user-center/myConsultation",
            component: () =>
              import("@/views/user-center/myConsultation/index.vue"),
            name: "我的咨询",
            meta: { title: "我的咨询" },
          },
          {
            path: "/user-center/myBid",
            component: () => import("@/views/user-center/myBid/index.vue"),
            name: "我的报价",
            meta: { title: "我的报价" },
          },
          {
            path: "/user-center/myCustomer",
            component: () => import("@/views/user-center/myCustomer/index.vue"),
            name: "我的客户",
            meta: { title: "我的客户" },
          },
          /* {
                                                                                                        path: '/user-center/handling',
                                                                                                        component: () => import('@/views/user-center/handling/index.vue'),
                                                                                                        name: '我的办理',
                                                                                                        meta: { title: '我的办理' },
                                                                                                    },
                                                                                                    {
                                                                                                        path: '/user-center/finance',
                                                                                                        component: () => import('@/views/user-center/finance/index.vue'),
                                                                                                        name: '我的金融',
                                                                                                        meta: { title: '我的金融' },
                                                                                                    }, */
          {
            path: "/user-center/settings",
            component: () => import("@/views/user-center/settings/index.vue"),
            name: "账号设置",
            meta: { title: "账号设置" },
          },
          {
            path: "/user-center/myAuth",
            component: () => import("@/views/user-center/auth/auth.vue"),
            name: "我的认证",
            meta: { title: "我的认证" },
          },
          {
            path: "/user-center/myBank",
            component: () => import("@/views/user-center/bank/index.vue"),
            name: "我的银行卡",
            meta: { title: "我的银行卡" },
          },
        ],
      },
      {
        path: "/user-center/order-details",
        component: () => import("@/views/user-center/orderDetails/index.vue"),
        name: "订单详情",
        meta: { title: "订单详情" },
      },
      {
        path: "/user-center/reviews",
        component: () => import("@/views/user-center/reviews/index.vue"),
        name: "商品评价",
        meta: { title: "商品评价" },
      },
      {
        path: "/user-center/refund",
        component: () => import("@/views/user-center/refund/index.vue"),
        name: "申请退款",
        meta: { title: "申请退款" },
      },
      // 订单相关
      {
        path: "/order-payment",
        component: () => import("@/views/order-payment/index.vue"),
        redirect: "/404",
        name: "订单支付",
        meta: { title: "" },
        children: [
          // {
          //     path: '/order-payment/settlement',
          //     component: () => import('@/views/order-payment/settlement/index.vue'),
          //     name: '结算页',
          //     meta: { title: '结算页' }
          // },
          {
            //  path: "/order-payment/service-settle",
            path: "/order-payment/:modules",
            component: () =>
              import("@/views/order-payment/service-settle/index.vue"),
            name: "结算页",
            meta: { title: "结算页" },
          },
          {
            path: "/order-payment/payment",
            component: () => import("@/views/order-payment/payment/index.vue"),
            name: "积分收银台",
            meta: { title: "积分收银台" },
          },
        ],
      },
      // 表单提交
      {
        path: "/form-fill",
        component: () => import("@/views/form-fill/index.vue"),
        redirect: "/404",
        name: "表单填写",
        meta: { title: "" },
        children: [
          {
            path: "/form-fill/supplier-entry",
            component: () =>
              import("@/views/form-fill/supplier-entry/index.vue"),
            name: "供应商入驻申请",
            meta: { title: "供应商入驻申请" },
          },
          {
            path: "/form-fill/merchant-account-application",
            component: () =>
              import(
                "@/views/form-fill/merchant-account-application/index.vue"
              ),
            name: "商户号开通申请",
            meta: { title: "商户号开通申请" },
          },
          {
            path: "/form-fill/person-account-application",
            component: () =>
              import("@/views/form-fill/person-account-application/index.vue"),
            name: "个人号开通申请",
            meta: { title: "个人号开通申请" },
          },
          {
            path: "/form-fill/demand-publish",
            component: () =>
              import("@/views/form-fill/demand-publish/index.vue"),
            name: "供需发布",
            meta: { title: "供需发布" },
          },
          {
            path: "/form-fill/organization-unite",
            component: () =>
              import("@/views/form-fill/organization-unite/index.vue"),
            name: "一键组社",
          },
          {
            path: "/form-fill/join-unite",
            component: () => import("@/views/form-fill/join-unite/index.vue"),
            name: "一键入社",
          },
          {
            path: "/form-fill/club-build",
            component: () => import("@/views/form-fill/club-build/index.vue"),
            name: "基层社建档",
          },

          /**
           * 2024年7月23日09:58:35
           */
          {
            path: "/form-fill/add-land",
            component: () => import("@/views/form-fill/add-land/index.vue"),
            name: "地块新增",
            meta: {
              title: "地块新增",
            },
          },
          {
            path: "/form-fill/lmplementationProgress",
            component: () =>
              import("@/views/form-fill/lmplementationProgress/index.vue"),
            name: "实施进度",
            meta: {
              title: "实施进度",
            },
          },
          {
            path: "/form-fill/add-subsidy-application",
            component: () =>
              import(
                "@/views/form-fill/service-organization/subsidy-application-management/subsidy-application/components/details.vue"
              ),
            name: "服务补贴申请表",
            meta: {
              title: "服务补贴申请表",
            },
          },
          {
            path: "/form-fill/historical-application",
            component: () =>
              import(
                "@/views/form-fill/service-organization/subsidy-application-management/historical-subsidy-application/components/details.vue"
              ),
            name: "历史申请详情",
            meta: {
              title: "历史申请详情",
            },
          },
          {
            path: "/form-fill/self-report-add",
            component: () =>
              import(
                "@/views/form-fill/service-organization/self-report-management/self-report/components/self-report-add.vue"
              ),
            name: "自评报告详情",
            meta: {
              title: "自评报告详情",
            },
          },
          {
            path: "/form-fill/service-declaration",
            component: () =>
              import(
                "@/views/form-fill/service-organization/service-organization-declaration-management/service-organization-declaration/components/service-declaration.vue"
              ),
            name: "服务组织申报详情",
            meta: {
              title: "服务组织申报详情",
            },
          },
          {
            path: "/form-fill/service-declaration-history",
            component: () =>
              import(
                "@/views/form-fill/service-organization/service-organization-declaration-management/service-organization-history/components/service-declaration.vue"
              ),
            name: "服务组织申报详情历史",
            meta: {
              title: "服务组织申报详情历史",
            },
          },
          {
            path: "/form-fill/directories-manage-details",
            component: () =>
              import(
                "@/views/form-fill/service-organization/directories-manage/components/details.vue"
              ),
            name: "服务主体详细信息",
            meta: {
              title: "服务主体详细信息",
            },
          },
          {
            path: "/form-fill/service-organization",
            component: () =>
              import("@/views/form-fill/service-organization/index.vue"),
            name: "服务组织申报",
            meta: {
              title: "服务组织申报",
            },
            children: [
              {
                path: "",
                redirect: "/form-fill/service-organization/directories-manage",
              },
              {
                path: "/form-fill/service-organization/directories-manage",
                component: () =>
                  import(
                    "@/views/form-fill/service-organization/directories-manage/index.vue"
                  ),
                name: "名录库",
                icon: new URL(
                  "@/views/form-fill/service-organization/img/mlk.png",
                  import.meta.url
                ).href,
                meta: {
                  title: "名录库",
                },
              },
              {
                path: "/form-fill/service-organization/bottom-up-investigation",
                component: () =>
                  import(
                    "@/views/form-fill/service-organization/bottom-up-investigation/index.vue"
                  ),
                name: "摸底调查",
                icon: new URL(
                  "@/views/form-fill/service-organization/img/mlk.png",
                  import.meta.url
                ).href,
                meta: {
                  title: "摸底调查",
                },
              },
              //{
              //    path: "/form-fill/service-organization/spotCheck-selfAssessment",
              //    component: () =>
              //        import(
              //            "@/views/form-fill/service-organization/spotCheck-selfAssessment/index.vue"
              //        ),
              //    name: "抽查自评",
              //    icon: new URL(
              //        "@/views/form-fill/service-organization/img/mlk.png",
              //        import.meta.url
              //    ).href,
              //    meta: {
              //        title: "抽查自评",
              //    },
              //},
              //{
              //    path: "/form-fill/service-organization/service-organization-declaration-management",
              //    component: () =>
              //        import(
              //            "@/views/form-fill/service-organization/service-organization-declaration-management/index.vue"
              //        ),
              //    name: "服务组织申报管理",
              //    icon: new URL(
              //        "@/views/form-fill/service-organization/img/zpbg.png",
              //        import.meta.url
              //    ).href,
              //    meta: { title: "服务组织申报管理" },
              //    children: [
              //        {
              //            path: "/form-fill/service-organization/service-organization-declaration-management/service-organization-declaration",
              //            component: () =>
              //                import(
              //                    "@/views/form-fill/service-organization/service-organization-declaration-management/service-organization-declaration/index.vue"
              //                ),
              //            name: "服务申报",
              //            meta: { title: "服务申报" },
              //        },
              //        {
              //            path: "/form-fill/service-organization/service-organization-declaration-management/service-organization-history",
              //            component: () =>
              //                import(
              //                    "@/views/form-fill/service-organization/service-organization-declaration-management/service-organization-history/index.vue"
              //                ),
              //            name: "历史申报",
              //            meta: { title: "历史申报" },
              //        },
              //    ],
              //},
              //{
              //    path: "/form-fill/service-organization/land-management",
              //    component: () =>
              //        import(
              //            "@/views/form-fill/service-organization/land-management/index.vue"
              //        ),
              //    name: "地块管理",
              //    icon: new URL(
              //        "@/views/form-fill/service-organization/img/dk.png",
              //        import.meta.url
              //    ).href,
              //    meta: {
              //        title: "地块管理",
              //    },
              //},
              {
                path: "/form-fill/service-organization/project-implementation-management",
                component: () =>
                  import(
                    "@/views/form-fill/service-organization/project-implementation-management/index.vue"
                  ),
                name: "项目实施管理",
                icon: new URL(
                  "@/views/form-fill/service-organization/img/org.png",
                  import.meta.url
                ).href,
                meta: {
                  title: "项目实施管理",
                },
                children: [
                  //  {
                  //    path: "/form-fill/service-organization/project-implementation-management/contract-implementation",
                  //    component: () =>
                  //      import(
                  //        "@/views/form-fill/service-organization/project-implementation-management/contract-implementation/index.vue"
                  //      ),
                  //    name: "项目合同实施",
                  //    meta: { title: "项目合同实施" },
                  //  },
                  {
                    path: "/form-fill/service-organization/project-implementation-management/project-practice",
                    component: () =>
                      import(
                        "@/views/form-fill/service-organization/project-implementation-management/project-practice/index.vue"
                      ),
                    name: "项目实施",
                    meta: {
                      title: "项目实施",
                    },
                  },
                  //  {
                  //    path: "/form-fill/service-organization/project-implementation-management/acceptance-application",
                  //    component: () =>
                  //      import(
                  //        "@/views/form-fill/service-organization/project-implementation-management/acceptance-application/index.vue"
                  //      ),
                  //    name: "验收申请",
                  //    meta: {
                  //      title: "验收申请",
                  //    },
                  //  },
                  //  {
                  //    path: "/form-fill/service-organization/project-implementation-management/historical-project",
                  //    component: () =>
                  //      import(
                  //        "@/views/form-fill/service-organization/project-implementation-management/historical-project/index.vue"
                  //      ),
                  //    name: "历史项目",
                  //    meta: {
                  //      title: "历史项目",
                  //    },
                  //  },
                ],
              },
              //  {
              //    path: "/form-fill/service-organization/subsidy-application-management",
              //    component: () =>
              //      import(
              //        "@/views/form-fill/service-organization/subsidy-application-management/index.vue"
              //      ),
              //    name: "补贴申请管理",
              //    icon: new URL(
              //      "@/views/form-fill/service-organization/img/bt.png",
              //      import.meta.url
              //    ).href,
              //    meta: {
              //      title: "补贴申请管理",
              //    },
              //    children: [
              //      {
              //        path: "/form-fill/service-organization/subsidy-application-management/subsidy-application",
              //        component: () =>
              //          import(
              //            "@/views/form-fill/service-organization/subsidy-application-management/subsidy-application/index.vue"
              //          ),
              //        name: "补贴申请",
              //        meta: {
              //          title: "补贴申请",
              //        },
              //      },
              //      {
              //        path: "/form-fill/service-organization/subsidy-application-management/historical-subsidy-application",
              //        component: () =>
              //          import(
              //            "@/views/form-fill/service-organization/subsidy-application-management/historical-subsidy-application/index.vue"
              //          ),
              //        name: "历史申请",
              //        meta: {
              //          title: "历史申请",
              //        },
              //      },
              //    ],
              //  },
              //  {
              //    path: "/form-fill/service-organization/self-report-management",
              //    component: () =>
              //      import(
              //        "@/views/form-fill/service-organization/self-report-management/index.vue"
              //      ),
              //    name: "自评报告管理",
              //    icon: new URL(
              //      "@/views/form-fill/service-organization/img/zpbg.png",
              //      import.meta.url
              //    ).href,
              //    meta: {
              //      title: "自评报告管理",
              //    },
              //    children: [
              //      {
              //        path: "/form-fill/service-organization/self-report-management/self-report",
              //        component: () =>
              //          import(
              //            "@/views/form-fill/service-organization/self-report-management/self-report/index.vue"
              //          ),
              //        name: "自评报告",
              //        meta: {
              //          title: "自评报告",
              //        },
              //      },
              //    ],
              //  },
            ],
          },
        ],
      },
      {
        path: "/network",
        component: () => import("@/views/network/index.vue"),
        name: "湖北供应链服务网络",
        meta: { title: "湖北供应链服务网络" },
        children: [],
      },

      {
        path: "/customer-service/:modules",
        component: () => import("@/views/customer-service/index.vue"),
        name: "在线磋商",
        meta: { title: "在线磋商" },
        children: [],
      },
      // 无权限限制表格
      {
        path: "/table-visible",
        component: () => import("@/views/table-visible/index.vue"),
        redirect: "/404",
        name: "可见表格",
        meta: { title: "" },
        children: [
          // {
          //     path: '/table-visible/supply-demand',
          //     component: () => import('@/views/table-visible/supply-demand/index.vue'),
          //     name: '供需对接',
          //     meta: { title: '供需对接' },
          //     children: [
          //         {
          //             path: '/table-visible/supply-demand/details',
          //             component: () => import('@/views/table-visible/supply-demand/details.vue'),
          //             name: '供需详情',
          //             meta: { title: '供需详情' },
          //         }
          //     ]
          // },
          // {
          //     path: '/table-visible/market',
          //     component: () => import('@/views/table-visible/market/index.vue'),
          //     name: '市场行情',
          //     meta: { title: '市场行情' }
          // },
          {
            path: "/table-visible/agricultural-technology",
            component: () =>
              import("@/views/table-visible/agricultural-technology/index.vue"),
            name: "农技推广",
            meta: { title: "农技推广" },
            children: [
              {
                path: "/table-visible/agricultural-technology/details",
                component: () =>
                  import(
                    "@/views/table-visible/agricultural-technology/details.vue"
                  ),
                name: "农技推广详情",
                meta: { title: "农技推广详情" },
              },
            ],
          },
        ],
      },
      {
        path: "/agreement-terms",
        component: () => import("@/views/agreementTerms/index.vue"),
        name: "协议条款",
        meta: { title: "协议条款" },
        children: [],
      },
    ],
  },
  {
    path: "/order-payment/icbc-payment",
    component: () => import("@/views/order-payment/icbc-payment/index.vue"),
    name: "收银台",
    meta: { title: "收银台" },
  },
  {
    path: "/appPlay",
    component: () => import("@/views/appPlay/index.vue"),
    name: "app支付",
    meta: { title: "app支付" },
  },
  {
    path: "/large-screen/three-agriculture",
    component: () => import("@/views/large-screen/three-agriculture/index.vue"),
    name: "三农大数据",
    meta: { title: "三农大数据" },
  },
  {
    path: "/404",
    component: () => import("@/views/error-page/index.vue"),
    hidden: true,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

export default routes;
