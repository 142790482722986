import request from '@/plugin/axios/request'

export function getPageFun(params) {
    return request({
        url: '/product/memberProducts/pageUser',
        method: 'get',
        params
    })
}

export function getDetailsFun(params) {
    return request({
        url: `/product/memberProducts/get/${params}`,
        method: 'get',
        params
    })
}

export function addCartItem(data) {
    return request({
        url: '/product/memberCart/addCartItem',
        method: 'post',
        data
    })
}

export function getMemberInfoFun(params) {
    return request({
        url: '/admin/membership/getMyInfo',
        method: 'get',
        params
    })
}

export function activeAccount(data) {
    return request({
        url: '/admin/membership/activation',
        method: 'post',
        data
    })
}

export function getCartList(params) {
    return request({
        url: '/product/memberCart/getCartList',
        method: 'get',
        params
    })
}


export function editCartNum(data) {
    return request({
        url: '/product/memberCart/editCartNum',
        method: 'post',
        data
    })
}

export function removeCart(data) {
    return request({
        url: '/product/memberCart/deleteCart',
        method: 'post',
        data
    })
}

export function applyMember(data,_api) {
    return request({
        url: `/admin/membership/${_api}`,// save edit
        method: 'post',
        data
    })
}

export function selectGet(params) {
    return request({
        url: '/admin/membership/getCooList',
        method: 'get',
        params
    })
}

export function pagePay(params) {
    return request({
        url: '/admin/memberPointRecord/pagePay',
        method: 'get',
        params
    })
}

export function pageGet(params) {
    return request({
        url: '/admin/memberPointRecord/pageGet',
        method: 'get',
        params
    })
}

export function getOrder(params) {
    return request({
        url: '/order/memberOrder/getOrderConfirmInfo',
        method: 'get',
        params
    })
}

export function getOrders(params) {
    return request({
        url: '/order/memberOrder/getOrderConfirmInfoByIds',
        method: 'get',
        params
    })
}

export function save(data) {
    return request({
        url: '/order/memberOrder/submitOrder',
        method: 'post',
        data
    })
}

export function getOrderPageFun(params) {
    return request({
        url: '/order/memberOrder/orderUserPage',
        method: 'get',
        params
    })
}

export function getOrderDetailsFun(params) {
    return request({
        url: '/order/memberOrder/orderDetail',
        method: 'get',
        params
    })
}

export function confirmReceipt(data) {
    return request({
        url: '/order/memberOrder/confirmReceipt',
        method: 'post',
        data
    })
}

//获取会员卡使用说明
export function getProtocolByValue(params) {
    return request({ 
        url: '/admin/protocol/getProtocolByValue',
        method: 'get',
        params
    })
}
