import imRequest from "@/plugin/axios/imRequest";
import request from "@/plugin/axios/request";
export function getByCode(params) {
  return request({
    url: "/admin/dict/getByCode/" + params,
    method: "get",
  });
}
// 登录
export function login(data) {
  return imRequest({
    url: "/user/login",
    method: "post",
    data,
  });
}
// 获取签名
export function getSignature(params) {
  return imRequest({
    url: "/chat/getSign",
    method: "get",
    params,
  });
}
// 获取会话列表
export function getConversationList(params) {
  return imRequest({
    url: "/chat/getConversationList",
    method: "get",
    params,
  });
}
// 删除会话列表
export function deleteConversation(params) {
  return imRequest({
    url: "/chat/deleteConversation",
    method: "get",
    params,
  });
}
// 获取消息列表
export function getMessageList(data) {
  return imRequest({
    url: "/chat/getMessageList",
    method: "post",
    data,
  });
}
// 重置离线消息
export function resetOfflineMsg(params) {
  return imRequest({
    url: "/chat/resetOfflineNum",
    method: "get",
    params,
  });
}
// 新增会话记录
export function save(data) {
  return request({
    url: "/admin/consultation/save",
    method: "post",
    data,
  });
}
// 查询该用户的会话列表
export function getUserConversation(params) {
  return request({
    url: "/admin/consultation/getListByUserId",
    method: "get",
    params,
  });
}

export function getUserSupplyConversation(params) {
  return request({
    url: "/admin/consultation/getListByUserId",
    method: "get",
    params,
  });
}

// 合同类型
export function dictgetPage(params) {
  return request({
    url: "admin/dict/getPage",
    method: "get",
    params,
  });
}
