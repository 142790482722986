import request from "@/plugin/axios/request";

//table懒加载
export function loadGet(url, params) {
  return request({
    url: url,
    method: "get",
    params,
  });
}
