const contextMenuId = "_ctx_menu" + Math.random().toString(36).slice(2);
const removeContainer = () => {
    const container = document.querySelector(`#${contextMenuId}`);
    if (container) {
        document.body.removeChild(container);
    }
};
const contextmenu = {
    mounted: (el, binding) => {
        el.style.cursor = "pointer";
        el.oncontextmenu = (evt) => {
            evt.preventDefault();
            removeContainer();
            const container = document.createElement("div");
            container.setAttribute("id", contextMenuId);
            container.setAttribute(
                "style",
                `    position: fixed;
                    left: ${evt.x}px;
                    top: ${evt.y}px;
                    background: #fff;
                    box-shadow: 2px 5px 10px -1px #0002;
                    width: 120px;
                    border-radius: 4px;
                    cursor:default;
                    border:1px solid #0002
                `
            );
            document.body.addEventListener("click", () => {
                removeContainer();
            });
            ["删除", "聊天"].forEach((t, i) => {
                const item = document.createElement("div");
                item.setAttribute(
                    "style",
                    `
                    padding: 8px 15px;
                    font-size: 14px;
                    cursor: pointer;
                    transition: 0.1s ease-in-out all;
                    ${i == 1 ? "" : "border-bottom:1px solid #0002"};
                    `
                );
                item.onclick = () => {
                    try {
                        binding.value({ type: t });
                    } catch (e) {
                        /*empty*/
                    }
                };
                item.innerText = t;
                container.appendChild(item);
                item.onmouseenter = () => {
                    item.style.color = "var(--el-color-primary)";
                    item.style.background = "#0001";
                };
                item.onmouseleave = () => {
                    item.style.color = "#000";
                    item.style.background = "transparent";
                };
            });
            document.body.appendChild(container);
        };
    },
};

export default {
    install(app) {
        app.directive("ctx-menu", contextmenu);
    },
};