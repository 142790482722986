<template>
  <el-upload
    ref="fileUpload"
    v-loading="state.loading"
    :file-list="state.fileList"
    :show-file-list="true"
    :limit="limit"
    :on-preview="handlePreview"
    :on-change="handleUpload"
    :on-remove="handleRemove"
    :http-request="fileUpload"
    action=""
    :auto-upload="false"
    list-type="text">
    <el-button
      type="primary"
      v-if="state.fileList.length < props.limit"
      >选择文件</el-button
    >
    <div
      v-else
      @click.stop
      class="el-button el-button--primary el-button--small is-disabled">
      <span>选择文件</span>
    </div>
    <template #tip>
      <div class="el-upload__tip">最多上传{{ props.limit }}个文件</div>
    </template>
  </el-upload>
</template>

<script setup>
  import { reactive, toRefs, ref, computed, onMounted } from "vue";
  import { uploadFiles } from "@/api/common/file";
  import { ElMessage } from "element-plus";

  const emit = defineEmits(["uploadSuccess", "update:modelValue"]);
  const props = defineProps({
    fileSize: {
      type: Number,
      default: 100,
    },
    limit: {
      type: Number,
      default: 5,
    },
    title: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    accept: {
      type: String,
      default: "",
    },
    fileText: {
      type: String,
      default: "",
    },
  });
  const state = reactive({
    loading: false,
    fileList: [],
    formData: null,
  });

  watch(
    () => props.modelValue,
    (n, o) => {
      if (n && n.length) {
        state.fileList = n.map((item) => {
          return {
            name: item.fileName,
            url: item.path,
            ...item,
          };
        });
      } else if (!!n.path) {
        n = {
          ...n,
          name: n.fileName,
        };
        state.fileList = [n];
      } else {
        state.fileList = [];
      }
    },
    { immediate: true }
  );

  const handleRemove = (file, fileList) => {
    state.fileList = fileList;
    emit("update:modelValue", state.fileList);
    emit("uploadSuccess", state.fileList, props.title);
  };
  const handleUpload = (file, fileList) => {
    const isLimit = file.size / 1024 / 1024 < props.fileSize;
    if (!isLimit) {
      ElMessage.error(`上传文件大小不能超过 ${props.fileSize}MB!`);
      fileList.splice(fileList.length - 1, 1);
      return;
    }
    state.loading = true;
    state.formData = new FormData();
    state.formData.append("files", file.raw);
    uploadFiles(state.formData)
      .then((res) => {
        let file = {
          id: res.data[0].id,
          url: res.data[0].path,
          name: res.data[0].fileName,
        };
        fileList.splice(fileList.length - 1, 1, file);
        state.fileList = fileList;
        ElMessage({
          message: res.success ? "文件上传成功！" : "文件上传失败！",
          type: res.success ? "success" : "error",
        });
        // res.success && emit("uploadSuccess");
        if (res.success) {
          emit("uploadSuccess", state.fileList, props.title);
          emit("update:modelValue", state.fileList);
        }
        setTimeout(() => {
          state.loading = false;
        }, 500);
      })
      .catch((error) => {
        ElMessage.error("文件上传失败！");
        state.loading = false;
      });
  };
  const fileUpload = () => {};
  //预览查看
  const handlePreview = (file) => {
    window.open(file.url, "_blank");
  };
  defineExpose({
    state,
  });
</script>

<style lang="scss" scoped></style>
