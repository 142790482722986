/** @format */

import { Im, Events, Message, destroy } from "kfjssdk";

import {
  login,
  getSignature,
  getMessageList,
  getConversationList,
  getUserConversation,
} from "@/api/common/customer-service.js";

import Cookies from "@/plugin/cookies/cookie";

import { parseTime, getLastMsg } from "@/utils/common.js";

import { ElNotification, ElMessageBox } from "element-plus";

import emitter from "@/plugin/emitter";

import router from "@/router";
import useUserStore from "@/store/moudules/user.js";
const route = router.currentRoute;
const whitePageList = [
  "/user-center/myInformation",
  "/customer-service/online-consultation",
];

export class Messages {
  // 当前登陆人ID
  userId = "";
  // 会话实例
  im = null;
  // 未读统计
  unreadCount = 0;
  // 会话列表
  conversationList = [];
  // 进行会话
  conversationID = "";
  // 签名
  signature = "";

  loading = false;

  timer = null;

  notice = null;

  constructor(loading = false, userId = Cookies.get("userId")) {
    this.loading = loading;
    this.userId = userId;
    this.init();
  }

  // 初始化
  async init() {
    if (this.userId) {
      // 获取Token
      const loginRes = await login({
        username: "yh",
        password: "123456",
      });

      // 保存Token
      Cookies.set("im_token", loginRes.data.access_token);

      // 获取会话列表
      this.getConversationList();

      // 登录IM
      this.loginIm();
    }
  }

  destroy() {
    destroy();
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
      this.im = null;
    }
  }

  async loginIm() {
    //key
    const appId = "42bcb797f9a54fa9892645640be3c456";

    //签名
    const signature = await (await getSignature({ appId })).data;

    //初始化im
    this.im = await Im({
      url: "wss://imsk.coopgxs.com",
      platform: "pc-web" + new Date().getTime(),
      timeout: 60,
      complete(e) {
        console.log(e, "complete");
      },
      success(e) {
        console.log(e, "success");
      },
      fail(e) {
        console.log(e, "fail");
      },
    });

    //登录im系统
    await this.im.login(appId, signature, this.userId);

    // 定时查询连接状态
    if (!this.timer) {
      this.timer = setInterval(() => {
        if (this.im && this.im.ws) {
          console.log(">>>>连接状态：", this.im.ws.readyState);
          const onlineStatus = this.im.ws.readyState == 1;
          if (!onlineStatus) {
            this.im = null;
            clearInterval(this.timer);
            this.timer = null;
          }
        } else {
          console.log(">>>>断开连接了，在重连中...");
          this.destroy();
          setTimeout(() => {
            this.init();
          }, 5000);
        }
      }, 5000);
    }

    /**
     * 监听事件
     */
    //文本消息
    this.im.on(
      Events.text,
      (data) => {
        console.log("文本消息");
        this.disposeReceiveMsg(data);
      },
      "textEvtGlobal"
    );

    //图片消息
    this.im.on(
      Events.image,
      (data) => {
        console.log("图片消息");
        this.disposeReceiveMsg(data);
      },
      "imageEvtGlobal"
    );

    // 监听被挤下线
    this.im.on(
      Events.kickout,
      (data) => {
        console.log("被挤下线了");
        // if (whitePageList.some(item => item === route.value.path)) return
        this.destroy();
        ElMessageBox.confirm(
          "有同类设备登录此账号，消息通知已失效, 是否继续?",
          "提示",
          {
            confirmButtonText: "重新连接",
            cancelButtonText: "取消",
            type: "warning",
            "close-on-click-modal": false,
          }
        ).then(() => {
          this.loginIm();
          emitter.emit("getMsg");
        });
      },
      "out"
    );

    this.im.on(
      Events.close,
      (data) => {
        console.log(data, "断开了");
        // if (whitePageList.some(item => item === route.path)) return
        this.im = null;
      },
      "close"
    );
  }

  // 处理消息
  disposeReceiveMsg(data) {
    if (data.conversationID) {
      if (this.conversationID != data.conversationID) {
        if (
          route.value.fullPath !== "/user-center/myInformation" &&
          !this.notice
        ) {
          this.notice = ElNotification({
            title: "消息提示",
            dangerouslyUseHTMLString: true,
            message:
              "你有新的磋商消息，<a style='color: #409EFF;'>点击跳转查看</a>",
            type: "info",
            duration: 0,
            onClose: () => {
              this.notice = null;
            },
            onClick: () => {
              this.notice.close();
              router.push({
                path: "/user-center/myInformation",
                query: {
                  toId: data.from,
                },
              });
            },
          });
        }

        // 在线未读 缓存
        this.cacheLocalUnreadData(data);

        let index = 0,
          exitConversationInList = false;
        exitConversationInList = this.conversationList.some((e, i) => {
          if (e.conversationID == data.conversationID) {
            index = i;
            return e.conversationID == data.conversationID;
          }
        });

        if (exitConversationInList) {
          // 在会话列表
          this.conversationList[index].userStatus = "在线";
          this.conversationList[index].offlineNum += 1;
          this.conversationList[index].lastMsg = data.payload;
          this.conversationList[index].time = data.time;
          this.conversationList = this.conversationList.sort((a, b) => {
            return b.offlineNum - a.offlineNum;
          });
          emitter.emit("receiveMsg", {
            ...data,
            chating: false,
            inList: true,
          });
        } else {
          // 不在会话列表
          this.conversationList.push({
            conversationID: data.conversationID,
            lastMsg: data.payload,
            offlineNum: 1,
            time: data.time,
            to: data.from,
            userStatus: "在线",
          });
          this.conversationList = this.conversationList.sort((a, b) => {
            return b.offlineNum - a.offlineNum;
          });

          emitter.emit("receiveMsg", {
            ...data,
            chating: false,
            inList: false,
          });
        }
      } else {
        emitter.emit("receiveMsg", {
          ...data,
          chating: true,
          inList: true,
        });
      }
    } else {
    }
    // 统计未读消息
    this.calcUnreadCount();
  }

  // 缓存在线未读本地数据
  cacheLocalUnreadData(data) {
    let unreadOnlineData = Cookies.get("unreadOnlineData")
      ? JSON.parse(Cookies.get("unreadOnlineData"))
      : [];
    unreadOnlineData.push(data);
    Cookies.set("unreadOnlineData", JSON.stringify(unreadOnlineData));
  }

  // 合并缓存本地未读消息
  handleUnreadLocalData() {
    let unreadOnlineData = Cookies.get("unreadOnlineData")
      ? JSON.parse(Cookies.get("unreadOnlineData"))
      : [];

    unreadOnlineData.map((data) => {
      this.conversationList.some((e) => {
        if (e.conversationID == data.conversationID) {
          e.offlineNum += 1;
          e.lastMsg = data.payload;
          e.time = data.time;
          return true;
        }
      });
    });

    this.conversationList = this.conversationList.sort((a, b) => {
      return b.offlineNum - a.offlineNum;
    });
  }

  // 清空当前会话离线条数
  clearCurrentConversationOfflineNum(conversationID) {
    this.conversationList.some((e, i) => {
      if (e.conversationID == conversationID) {
        e.offlineNum = 0;
        return true;
      }
    });

    // 清除本地未读缓存
    let unreadOnlineData = Cookies.get("unreadOnlineData")
      ? JSON.parse(Cookies.get("unreadOnlineData"))
      : [];
    let newUnreadOnlineData = unreadOnlineData.filter((e) => {
      return e.conversationID != conversationID;
    });
    Cookies.set("unreadOnlineData", JSON.stringify(newUnreadOnlineData));
    emitter.emit("clearMsg", newUnreadOnlineData);

    // 重新计算未读消息
    this.calcUnreadCount();
  }

  // 获取会话列表
  async getConversationList() {
    // 会话列表
    const res = await getConversationList({
      from: this.userId,
    });
    this.conversationList = res.data;

    // 处理本地缓存信息
    this.handleUnreadLocalData();

    // 统计未读消息
    this.calcUnreadCount();
  }

  // 获取未读数量
  getUnreadCount() {
    return this.unreadCount;
  }

  // 统计未读数量
  calcUnreadCount() {
    if (this.conversationList.length) {
      this.unreadCount = this.conversationList.reduce((sum, next) => {
        return sum + next.offlineNum;
      }, 0);
    }
    Cookies.set("unreadCount", this.unreadCount);
    emitter.emit("unreadCount");
  }
}
