import request from '@/plugin/axios/request'

export function loginByUsername(data) {
    return request({
        url: '/auth/user/login',//    /admin/user/register
        method: 'post',
        data
    })
}
export function loginByPhoneCode(data) {
    return request({
        url: '/admin/user/register',//  '/admin/user/loginBySms',
        method: 'post',
        data
    })
}
// export function register(data) {
//     return request({
//         url: '/admin/user/register',
//         method: 'post',
//         data
//     })
// }
export function getInfo(token) {
    return request({
        url: '/auth/user/info',
        method: 'get',
        params: { token }
    })
}
export function logout(data) {
    return request({
        url: '/auth/logout',
        method: 'get',
        params: data
    })
}
export function getPageUser(params) {
    return request({
        // url: '/auth/administrator/page',
        url: '/admin/user/getPageUser',
        method: 'get',
        params
    })
}

export function save(data, _api) {
    return request({
        url: `/admin/administrator/${_api}`, // save edit
        method: 'post',
        data
    })
}

export function getRoles(params) {
    return request({
        url: '/admin/administrator/getById',
        method: 'get',
        params
    })
}


