<template>
    <router-view v-if="isRouterAlive" />
</template>

<script setup>

import {
    reactive,
    toRefs,
    ref,
    computed,
    onMounted,
    nextTick,
    onUnmounted,
} from "vue";
import useStore from "@/store";
import Stomp from "stompjs";
import { getToken, setToken, removeToken } from "@/utils/auth";
import {
    MQTT_WSS_SERVICE,
    MQTT_WS_SERVICE,
    MQTT_USERNAME,
    MQTT_PASSWORD,
    MQTT_TEST_USERNAME,
    MQTT_TEST_PASSWORD,
} from "./config/mqtt.js";

const isRouterAlive = ref(true);
const reload = () => {
    isRouterAlive.value = false;
    nextTick(() => (isRouterAlive.value = true));
};
provide("reloadFun", reload);

const { appStore, user } = useStore();
const size = computed(() => appStore.size);
let client = reactive(null);
const expTtl = reactive(86400000);
onMounted(() => {
    init();
});

let init = () => {
    if (client == null) {
        connect();
    }
};

let connect = () => {
    //初始化mqtt客户端，并连接mqtt服务
    let login =
        import.meta.env.MODE === "development"
            ? MQTT_TEST_USERNAME
            : MQTT_USERNAME;
    let passWord =
        import.meta.env.MODE === "development"
            ? MQTT_TEST_PASSWORD
            : MQTT_PASSWORD;
    const headers = {
        login: login,
        passcode: passWord,
    };
    let url =
        import.meta.env.MODE === "development"
            ? MQTT_WS_SERVICE
            : MQTT_WSS_SERVICE;
    client = Stomp.client(url);
    client.connect(headers, onConnected, onFailed);
};

let onConnected = (frame) => {
    //订阅频道
    const topic = `/exchange/examined-approve-notice-user-exchange/examined-approve-notice-user-key${user.userId}`;

    client.subscribe(
        topic,
        (message) => {
            responseCallback(message);
        },
        {
            "x-expires": expTtl,
            ack: "client",
        }
    );
};

let onFailed = (frame) => {
    connect();
    console.log("MQ Failed: " + frame);
};

let responseCallback = (message) => {
    //接收消息处理
    if (message.body !== "") {
        let msg = JSON.parse(message.body).msg;
        ElNotification({
            title: "提示",
            message: msg,
            duration: 0,
            position: "top-right",
        });
        message.ack();
    }
};

function getQueryParams(url) {
    // 使用正则表达式提取URL参数
    let queryParams = {};
    let urlToParse = url || window.location.href;
    let questionMarkIndex = urlToParse.indexOf("?");
    if (questionMarkIndex !== -1) {
        let queryString = urlToParse.substring(questionMarkIndex + 1);
        let vars = queryString.split("&");
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            let key = decodeURIComponent(pair[0]);
            let value = decodeURIComponent(pair[1]);
            // 如果存在参数值则解析为正确的类型
            if (typeof queryParams[key] === "undefined") {
                queryParams[key] = value;
            } else if (Array.isArray(queryParams[key])) {
                queryParams[key].push(value);
            } else {
                queryParams[key] = [queryParams[key], value];
            }
        }
    }
    return queryParams;
}

const params = getQueryParams();

if (params.Itoken) {
    user.token = params.Itoken;
    setToken(params.Itoken);
    setTimeout(() => {
        user.getInfo();
    }, 1000);
}
</script>

<style lang="scss" scoped>
:deep(.el-form-item) {
    --font-size: $width * 14;
}

.el-button+.el-button {
    margin-left: 0px;
}
</style>
