import Cookies from 'js-cookie'

const cookies = {}

/**
 * @description 存储 cookie 值
 * @param {String} name cookie name
 * @param {String} value cookie value
 * @param {Object} setting cookie setting
 */
cookies.set = function(name = 'default', value = '', cookieSetting = {}) {
  const currentCookieSetting = {
    expires: 1,
    // 第三方cookie请求策略，避免跨站请求伪造（CSRF），参数为Strict（严格模式），Lax（宽松模式）
    sameSite: 'lax'
  }
  Object.assign(currentCookieSetting, cookieSetting)
  Cookies.set(`${import.meta.env.VITE_APP_BASE_NAME}-${name}`, value, currentCookieSetting)
}

/**
 * @description 拿到 cookie 值
 * @param {String} name cookie name
 */
cookies.get = function(name = 'default') {
  return Cookies.get(`${import.meta.env.VITE_APP_BASE_NAME}-${name}`)
}

/**
 * @description 拿到 cookie 全部的值
 */
cookies.getAll = function() {
  return Cookies.get()
}

/**
 * @description 删除 cookie
 * @param {String} name cookie name
 */
cookies.remove = function(name = 'default') {
  return Cookies.remove(`${import.meta.env.VITE_APP_BASE_NAME}-${name}`)
}

export default cookies
