import { getToken } from '@/utils/auth'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import useStore from '@/store'

// create an axios instance 创建一个axios实例
export const request = axios.create({
    baseURL: import.meta.env.VITE_APP_BASE_API,
    withCredentials: true,
    headers: {
        'accessToken': getToken(),
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    },
    timeout: 1200000
})
request.interceptors.request.use(
    (config) => {
        const { user } = useStore()
        config.headers["Access-Control-Allow-Origin"] = "*";
        config.headers["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8";
        if (user.token) {
            config.headers[import.meta.env.VITE_APP_TOKEN_KEY] = 'Bearer ' + getToken()
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
// response interceptor
request.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code !== 200) {
            ElMessage({
                message: res.message || 'error',
                type: 'error',
                duration: 15 * 1000
            })
            return Promise.reject(res.message || 'error')
        } else {
            return res
        }
    },
    error => {
        ElMessage({
            message: error,
            type: 'error',
            duration: 15 * 1000
        })
        return Promise.reject(error)
    }
)

export function uploadFiles(data, callBack, url = '/file/file/upload') {
    return request({
        url,
        method: 'post',
        data: data,
        onUploadProgress: progressEvent => {
            // console.log(progressEvent)
            // console.log(progressEvent.lengthComputable)
            // if (progressEvent.lengthComputable) {
            //   const percent = parseInt((progressEvent.loaded / progressEvent.total * 100).toFixed(0))
            //   // 调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
            //   callBack(percent)
            // }
        }
    })
}

export function identifyCard(data, callBack) {
    return request({
        url: '/admin/identify/identifyCard',
        method: 'post',
        data: data,
       
    })
}

export function identifyBusinessLicense(data, callBack) {
    return request({
        url: '/admin/identify/identifyBusinessLicense',
        method: 'post',
        data: data,
       
    })
}


export function downloadFile(data) {
    const url = import.meta.env.VITE_APP_BASE_API + '/common/download/ftpFile?id=' + data
    window.location.href = url
}

