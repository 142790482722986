import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import Cookies from '@/plugin/cookies/cookie'

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

const service = axios.create({
    baseURL: import.meta.env.VITE_APP_IM_API,
    timeout: 30000
})

service.interceptors.request.use(
    config => {
        config.headers['Access-Control-Allow-Origin'] = '*'
        if (Cookies.get('im_token')) {
            config.headers[import.meta.env.VITE_APP_TOKEN_KEY] = 'Bearer ' + Cookies.get('im_token')
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code !== 200) {
            ElMessage({
                message: res.message || 'error',
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(res.message || 'error')
        } else {
            return res
        }
    },
    error => {
        if (error && error.response) {
            //  1.判断请求超时
            if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
                error.message = '服务器连接超时，请稍后再试！'
                Message({
                    message: error,
                    type: 'error',
                    duration: 5 * 1000
                })
                return Promise.reject(error)
            }
        }
        ElMessage({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service