import { defineStore } from "pinia";
import {
  loginByUsername,
  loginByPhoneCode,
  getInfo,
  logout,
} from "@/api/admin/user/user";
import { getToken, setToken, removeToken } from "@/utils/auth";
import Cookies from "@/plugin/cookies/cookie";
import { ElMessage } from "element-plus";
import { resolve } from "path-browserify";
import { getMemberInfoFun } from "@/api/user-center/member.js";
import { Messages } from "@/utils/message.js";

const useUserStore = defineStore("user", {
  state: () => {
    return {
      token: getToken(),
      userId: Cookies.get("userId"),
      userName: Cookies.get("userName"),
      nickName: "", // Cookies.get('nickName'),
      supplierId: Cookies.get("supplierId"),
      roles: Cookies.get("roles"),
      memberInfo: null,
      supplierName: Cookies.get("supplierName"),
      authenticationStatus: Cookies.get("authenticationStatus"),
    };
  },
  actions: {
    login(userInfo, type) {
      if (type == "code") {
        //验证码登录
        return new Promise((resolve, reject) => {
          loginByPhoneCode(userInfo)
            .then((res) => {
              const { data } = res;
              setToken(data.access_token);
              this.token = data.access_token;
              this.getInfo();

              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      } else {
        //密码登录
        return new Promise((resolve, reject) => {
          loginByUsername(userInfo)
            .then((res) => {
              const { data } = res;
              setToken(data.access_token);
              this.token = data.access_token;
              this.getInfo();

              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    getInfo() {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((res) => {
            const { data } = res;
            console.log("info--data", data);
            const {
              roles,
              username,
              avatar,
              menus,
              id,
              phone,
              nickName,
              supplierName,
              supplierId,
              authenticationStatus
            } = data;
            Cookies.set("userId", id);
            Cookies.set("userName", username);
            Cookies.set("nickName", nickName);
            Cookies.set("roles", roles);
            Cookies.set("phone", phone);
            Cookies.set("avatar", avatar);
            Cookies.set("supplierName", supplierName);
            Cookies.set("authenticationStatus", authenticationStatus);
            supplierId && Cookies.set("supplierId", supplierId);
            this.userId = id;
            this.userName = username;
            this.roles = roles;
            this.phone = phone;
            this.nickName = nickName;
            this.supplierName = supplierName;
            this.supplierId = supplierId;
            this.authenticationStatus = authenticationStatus;
            if (!Window.IM) {
              Window.IM = new Messages(true, id);
            }
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    logout() {
      return new Promise((resolve, reject) => {
        logout(this.token)
          .then((res) => {
            this.token = "";
            this.userName = "";
            this.userId = "";
            this.roles = "";
            this.supplierName = "";
            this.supplierId = "";
            removeToken();
            Cookies.remove("userId");
            Cookies.remove("userName");
            Cookies.remove("roles");
            Cookies.remove("supplierName");
            Cookies.remove("supplierId");
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    resetToken() {
      return new Promise((resolve, reject) => {
        this.token = "";
        this.userName = "";
        this.menus = "";
        removeToken();
        resolve();
      });
    },
    getMemberInfo() {
      return new Promise((resolve, reject) => {
        getMemberInfoFun()
          .then((res) => {
            this.memberInfo = res.data ? res.data : null;
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});

export default useUserStore;
